import { render, staticRenderFns } from "./Like.vue?vue&type=template&id=bc7d41cc&"
import script from "./Like.vue?vue&type=script&lang=ts&"
export * from "./Like.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Lottie: require('/tmp/build_acca07ff/apps/web/components/shared/Lottie.vue').default,KIcon: require('/tmp/build_acca07ff/apps/web/components/icons/KIcon.vue').default,KButton: require('/tmp/build_acca07ff/apps/web/components/buttons/KButton.vue').default})
